/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { WritableAtom } from "jotai";
import { enqueueSnackbar } from "notistack";
import { SnackbarRetryButton } from "../components/SnackbarRetryButton/SnackbarRetryButton";

/**
 * Handles errors associated with api atoms
 * @param error the error
 * @param refreshSetter a setter to trigger a refresh or retry
 */
export function handleError<Args extends unknown[], T>(
  error: Error,
  refreshSetter?: WritableAtom<unknown, Args, T>,
  ...args: Args
): void {
  enqueueSnackbar((error as Error).message, {
    variant: "error",
    action: refreshSetter ? <SnackbarRetryButton refreshAtom={refreshSetter} args={args} /> : undefined,
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  });
}
