/*
 * Copyright 2023 - 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement } from "react";
import { useAtom, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Error from "@mui/icons-material/Error";
import { ContentCopy, Warning } from "@mui/icons-material";
import { config } from "../../../config/AdminUiConfig";
import { LoadingButton } from "../../../components/LoadingButton/LoadingButton";
import { ControlledTextField } from "../../../components/HookForms/ControlledTextField";
import { JsonEditor } from "../../../components/JsonEditor/JsonEditor";
import {
  FetchFailedThreatMappingFields,
  fetchFailedThreatMappingFields,
  loadableFetchFailedThreatMappingState,
} from "../../../services/failedThreatMappingsApi";
import {
  DocumentQueryResponse,
  FailedThreatMappingsResponse,
} from "@sophos-socos/admin-api-client";
import { RegionStatusResponseTable } from "../RegionStatusResponseTable";
import { ALL_REGIONS_KEY } from "../../../helpers/AllRegionsKey";
import { formatThreatMappings } from "../../../helpers/formatThreatMappings";

/**
 * Threat mapping view component to display the threat mappings
 * @returns Threat mapping view component
 */
export function FailedThreatMappingsView(): ReactElement {
  const [result] = useAtom(loadableFetchFailedThreatMappingState);
  const dispatch = useSetAtom(fetchFailedThreatMappingFields);

  const threatMappingsData = result.state === "hasData" ? result.data : undefined;
  const threatMappingsResponse = threatMappingsData
    ? threatMappingsData
    : { sourceSystemThreatMappings: [], moreItems: false };
  // typecast is due to a limitation of typescript when merging additional fields in the openapi client
  const { sourceSystemThreatMappings, moreItems, ...regionStatusData } =
    threatMappingsResponse as FailedThreatMappingsResponse & DocumentQueryResponse;

  /** Create form to control fields for request */
  const { control, handleSubmit } = useForm<FetchFailedThreatMappingFields>({
    defaultValues: {
      pageSize: 500,
      region: ALL_REGIONS_KEY,
    },
  });

  /* Dispatch field updates to trigger fetch of threat mappings */
  const handleFetchThreatMappings = (data: FetchFailedThreatMappingFields) =>
    dispatch({ ...data, region: data.region === ALL_REGIONS_KEY ? undefined : data.region });

  return (
    <Container maxWidth="md">
      <Grid height="100%" container direction="column">
        <Grid item xs={1}>
          <Grid container direction="row">
            <Card sx={{ width: "100%", mt: 4, pr: 2, pb: 1 }} component="section">
              <Box sx={{ m: 3, mb: 1 }} display={"flex"} alignItems={"center"} flexDirection={"row"} width={"100%"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="h6">Get Failed Threat Mappings</Typography>
                </Box>
              </Box>
              <CardContent>
                <Grid container direction={"column"}>
                  <Grid item sm={2}>
                    <ControlledTextField
                      id="page-size"
                      label="Max mappings to get per region"
                      control={control}
                      name="pageSize"
                      type="number"
                      rules={{
                        min: { value: 1, message: "Min: 1" },
                      }}
                      sx={{ m: 1 }}
                    />
                    <ControlledTextField
                      id="region-select"
                      select
                      control={control}
                      sx={{ m: 1, minWidth: "5rem" }}
                      label="Region"
                      name="region"
                      rules={{
                        required: true,
                      }}
                    >
                      {[
                        <MenuItem key={ALL_REGIONS_KEY} value={ALL_REGIONS_KEY}>
                          All regions
                        </MenuItem>,
                        ...config.activeRegions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )),
                      ]}
                    </ControlledTextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Box sx={{ m: 1, mt: 0 }} display="flex" flexDirection="row" justifyContent="flex-end">
                <LoadingButton
                  state={result.state}
                  onClick={handleSubmit((data) => handleFetchThreatMappings(data))}
                  variant="contained"
                >
                  Get Failed Threat Mappings
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          {threatMappingsData ? (
            <>
              {moreItems ? (
                <Card sx={{ mt: 4, backgroundColor: "#fee897" }} component="section">
                  <CardContent>
                    <Box aria-label="more-threat-mapping-warning" display="flex" alignItems="center" fontSize="small">
                      <Warning color="warning" sx={{ mr: 2 }} fontSize="large" />
                      {`More threat mappings can be retrieved from the database. Either increase the "Max mappings to get
                  per region" and re-fetch the mappings, or map the following results, reprocess them, and then re-fetch the mappings.`}
                    </Box>
                  </CardContent>
                </Card>
              ) : null}
              {regionStatusData ? (
                <Card sx={{ mt: 4 }} component="section">
                  <RegionStatusResponseTable data={regionStatusData} />
                </Card>
              ) : null}
              {sourceSystemThreatMappings.map((sourceSystemMapping, index) => (
                <Card key={index} sx={{ mt: 4 }} component="section">
                  <CardContent>
                    <Box display="flex" alignItems="center" sx={{ justifyContent: "space-between", mb: "1rem" }}>
                      <Typography id={`source-system-${index}`} variant="h6">
                        {sourceSystemMapping.sourceSystem}
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          // use selector so any edits to the JSON also get copied to the clipboard
                          const editableThreatMappings =
                            document.getElementById(`threat-mappings-${index}`)?.querySelector(".cm-content")
                              ?.textContent || "[]";

                          // parse and format threat mappings
                          const parsedThreatMappings = JSON.parse(editableThreatMappings);

                          // write formatted results to the clipboard
                          navigator.clipboard.writeText(JSON.stringify(parsedThreatMappings, null, 2));
                        }}
                      >
                        <ContentCopy fontSize="small" sx={{ mr: "0.5rem" }} />
                        Copy
                      </Button>
                    </Box>
                    <div id={`threat-mappings-${index}`}>
                      <JsonEditor
                        content={{
                          text:
                            sourceSystemMapping.threatMappings !== undefined
                              ? formatThreatMappings(sourceSystemMapping.threatMappings)
                              : "[]",
                        }}
                        askToFormat={false}
                      />
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : result.state === "hasError" ? (
            <Box display="flex" justifyContent="center">
              <Box display="flex" alignItems="center">
                <Error color="error" sx={{ m: 2 }} />
                Failed to get threat mappings
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}
