/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import React from "react";
import { Controller, Control, RegisterOptions, Path, FieldValues } from "react-hook-form";
import { DesktopDateTimePicker, DesktopDateTimePickerProps } from "@mui/x-date-pickers";

/** Props for the controlled date picker field */
type ControlledDateTimePickerProps<DateTimePickerValues extends FieldValues> = {
  /** React hooks control */
  control: Control<DateTimePickerValues>;
  /** The name of the field */
  name: Path<DateTimePickerValues>;
  /** Validation rules */
  rules?: RegisterOptions;
} & DesktopDateTimePickerProps<DateTimePickerValues>;

/**
 * Controlled date picker for react hook forms
 * @template DateTimePickerValues
 * @param props The date picker props
 * @returns Controlled date picker
 */
export function ControlledDateTimePicker<DateTimePickerValues extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: ControlledDateTimePickerProps<DateTimePickerValues>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DesktopDateTimePicker
          {...props}
          value={value}
          onChange={(event) => onChange(event as any)}
          slotProps={{ textField: { error: !!error, helperText: error?.message } }}
        />
      )}
    />
  );
}
