/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement } from "react";
import { DocumentQueryResponse } from "@sophos-socos/admin-api-client";
import { Box } from "@mui/material";
import { JsonEditor } from "../../components/JsonEditor/JsonEditor";
import { JSONValue } from "vanilla-jsoneditor";

/** Props for the execution error response view */
type ExecutionErrorResponseProps = {
  /** The response data */
  data: DocumentQueryResponse;
};

export function ExecutionErrorResponse(props: ExecutionErrorResponseProps): ReactElement {
  return (
    <Box height="100%">
      {Object.entries(props.data).map(([, documentQueryResponse], index) => (
        <JsonEditor
          key={index}
          readOnly={true}
          askToFormat={true}
          content={{
            json: documentQueryResponse.result as JSONValue[] | JSONValue,
          }}
        ></JsonEditor>
      ))}
    </Box>
  );
}
