/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { type ReactElement } from "react";

/** Loading state props */
type LoadingButtonProps = {
  /** The loading state of an underlying operation */
  state: "hasData" | "hasError" | "loading" | "complete" | "initial";
} & ButtonProps<"button">;

/**
 * A loadings button that can display the loading state of an underlying data operation
 * @param props The loadings button props
 * @returns The loadings button
 */
export function LoadingButton({ state, ...props }: LoadingButtonProps): ReactElement {
  return (
    <Button {...props} disabled={props.disabled || state === "loading"}>
      <span style={{ visibility: state === "loading" ? "hidden" : "visible" }}>{props.children}</span>

      {state === "loading" ? <CircularProgress sx={{ color: "white", position: "absolute" }} size="1rem" /> : ""}
    </Button>
  );
}
