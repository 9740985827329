/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

import { env } from "./env";
import { tryParseJSON } from "../helpers/tryParse";

/** UI configuration */
export const config: AdminUiConfig = {
  apiUri: env("VITE_API_URL"),
  region: env("VITE_REGION"),
  userPoolId: env("VITE_COGNITO_USER_POOL_ID"),
  userPoolAppClientId: env("VITE_COGNITO_USER_POOL_APP_CLIENT_ID"),
  activeRegions: (tryParseJSON(env("VITE_REGIONS")) as string[]) || [],
  queryErrorPageSize: env("QUERY_ERROR_PAGE_SIZE") || 100,
};

/** The configuration of the admin UI */
interface AdminUiConfig {
  /** API base url */
  apiUri: string;

  /** AWS Region containing cognito instance  */
  region: string;

  /** AWS Cognito user pool ID */
  userPoolId: string;

  /** AWS Cognito user pool app client ID */
  userPoolAppClientId: string;

  /** Active regions where access is required  */
  activeRegions: string[];

  /** Page size for an error query result */
  queryErrorPageSize: number;
}
