/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement, useState, useMemo } from "react";
import { QueueItem } from "@sophos-socos/admin-api-client";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { loadableDeleteDeadLetterQueueState } from "../../services/deadLetterQueueApi";
import { useAtom } from "jotai";
import { JsonEditor } from "../../components/JsonEditor/JsonEditor";
import { Content } from "vanilla-jsoneditor";
import { tryParseJSON } from "../../helpers/tryParse";

/** Props for the execution count response table */
type MessageResponseTable = {
  /** Should the table render in a loading state */
  loading?: boolean;
  /** The response data */
  data: { queueItem: QueueItem; failedDeletionMessage?: string }[];
};

/** Column definitions for the Message Response Table */
const columns: GridColDef[] = [
  { field: "id", headerName: "Receipt Handle", width: 150 },
  { field: "messageId", headerName: "Message ID", width: 150 },
  { field: "body", headerName: "Body", width: 200 },
  { field: "errorMessage", headerName: "Error Message", width: 200 },
];

/** Detail dialog tabs */
enum DetailDialogTabs {
  body,
  error
}

/**
 * Message response table component to render the response data from SQS queues
 * @returns Message response table component
 */
export function MessageResponseTable(props: MessageResponseTable): ReactElement {
  /** Currently selected rows in the table */
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  /** Currently selected message to view in detail */
  const [viewingMessage, setViewingMessage] = useState<
    | {
        id: string;
        messageId: string;
        body: string | Content;
        errorMessage: string;
      }
    | undefined
  >(undefined);

  /** Active tab */
  const [activeTab, setActiveTab] = useState<DetailDialogTabs>(DetailDialogTabs.body);

  /** Open state for clear dialog */
  const [clearDialogOpen, setClearDialogOpen] = useState(false);

  /** Dispatch function to call delete DLQ items API */
  const [setterState, clear] = useAtom(loadableDeleteDeadLetterQueueState);

  /** Create table rows, accounting for items that have been deleted */
  const rows = useMemo(() => {
    if (props.data) {
      const queueItems = props.data || [];
      return queueItems.map((item) => ({
        id: item.queueItem.receiptHandle,
        messageId: item.queueItem.messageId,
        body: item.queueItem.body,
        errorMessage: item.failedDeletionMessage,
      }));
    } else {
      return [];
    }
  }, [props]);

  /** Handle submit of API call */
  const handleConfirmClick = () => {
    /** Close dialog and clear selections */
    setClearDialogOpen(false);

    /** Create request body items */
    const queueItems = rowSelectionModel.map((receiptHandle) => ({ receiptHandle: receiptHandle.toString() }));

    /** Trigger the delete DLQ items API */
    clear({
      queueItems,
    });
  };

  return (
    <Container>
      {/**
       * Clear items dialog
       */}
      <Dialog
        open={clearDialogOpen}
        onClose={() => {
          setClearDialogOpen(false);
        }}
        aria-labelledby="clear-dialog-title"
        aria-describedby="clear-dialog-description"
        id="clear-dialog"
      >
        <DialogTitle id="clear-dialog-title">{`Clear: ${rowSelectionModel.length} items`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="clear-dialog-description">
            {"Are you sure you want to clear these DLQ items?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setClearDialogOpen(false);
            }}
            autoFocus
          >
            {"Do not clear"}
          </Button>
          <Button
            data-testid="clear-dialog-submit"
            type="submit"
            onClick={handleConfirmClick}
            color="error"
            variant="contained"
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>

      {/**
       * Message Detail dialog
       */}
      <Dialog
        maxWidth="md"
        fullWidth
        open={viewingMessage !== undefined}
        onClose={() => {
          setViewingMessage(undefined);
        }}
        aria-labelledby="message-detail-dialog-title"
        aria-describedby="message-detail-dialog-description"
        id="message-detail-dialog"
        data-testid="message-detail-dialog"
      >
        <DialogTitle id="message-detail-dialog-title">{`Message detail: ${viewingMessage?.id}`}</DialogTitle>
        <Tabs value={activeTab}>
          <Tab value={DetailDialogTabs.body} label="body" onClick={() => setActiveTab(DetailDialogTabs.body)}></Tab>
          {viewingMessage?.errorMessage && (
            <Tab value={DetailDialogTabs.error} label="internal errors" onClick={() => setActiveTab(DetailDialogTabs.error)}></Tab>
          )}
        </Tabs>

        {activeTab === DetailDialogTabs.body && (
          <DialogContent>
            {typeof viewingMessage?.body !== "string" ? (
              <Card sx={{ p: 1 }}>
                <JsonEditor
                  readOnly={true}
                  askToFormat={true}
                  content={{
                    json: viewingMessage?.body || {},
                  }}
                ></JsonEditor>
              </Card>
            ) : (
              <Card sx={{ p: 1 }}>
                <Typography>{viewingMessage.body}</Typography>
              </Card>
            )}
          </DialogContent>
        )}
        {activeTab === DetailDialogTabs.error && (
          <DialogContent>
              <Card sx={{ p: 1 }}>
                <Typography>{viewingMessage?.errorMessage || ""}</Typography>
              </Card>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            onClick={() => {
              setViewingMessage(undefined);
            }}
            autoFocus
          >
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid flexDirection={"column"} container>
        <Grid xs={10} minWidth="100%" item>
          <DataGrid
            sx={{
              // disable border when clicking a specific cell
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            disableVirtualization
            autoHeight
            data-testid="message-response-table"
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            onRowClick={(eventObject) => {
              const parsedBody = tryParseJSON(eventObject.row.body);

              setViewingMessage({
                ...eventObject.row,
                body: parsedBody ? parsedBody : eventObject.row.body,
              });
            }}
            rowSelectionModel={rowSelectionModel}
            rows={rows}
            columns={columns}
            loading={props.loading || setterState.state === "loading"}
          />
        </Grid>
        <Grid display={"flex"} justifyContent={"flex-end"} minWidth={"100%"} xs={2} item>
          <Box padding="0.25rem">
            <Button
              onClick={() => {
                setClearDialogOpen(true);
              }}
              disabled={rowSelectionModel.length === 0}
              variant="contained"
            >
              Clear selected items
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
