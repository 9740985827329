/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { Button } from "@mui/material";
import { type ReactElement } from "react";
import styled from "@emotion/styled";
import { WritableAtom, useSetAtom } from "jotai";

/** Retry button */
const RetryButton = styled(Button)`
  color: white;
`;

/** Loading state props */
type SnackbarRetryButtonProps<Args extends unknown[]> = {
  /** A refresh atom that will trigger a refresh when set */
  refreshAtom: WritableAtom<unknown, Args, any>;
  /** Arguments */
  args: Args;
};

/**
 * A retry button for the snackbar that can refresh an atom
 * @param props The snackbar retry button props
 * @returns The snackbar retry button
 */
export function SnackbarRetryButton<Args extends unknown[]>({
  refreshAtom,
  args,
}: SnackbarRetryButtonProps<Args>): ReactElement {
  const refresh = useSetAtom(refreshAtom);

  return (
    <div>
      <RetryButton
        variant="text"
        color="info"
        onClick={() => {
          refresh(...args);
        }}
      >
        Retry
      </RetryButton>
    </div>
  );
}
