/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/**
 * Gets the environmental variable
 *
 * @param key variable key, should include the "VITE" prefix
 * @returns the value of the environmental variable
 */
export function env(key: string) {
  return (window as unknown as { _env: Record<string, string> })._env?.[key] || import.meta.env[key];
}
