/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { QueueApi, QueueItem } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { handleError } from "./apiError";
import { accessTokenAtom } from "./userService";
import { enqueueSnackbar } from "notistack";
import { loadableSet } from "../helpers/loadableSetter";
import { QueueTypeEnum } from "../enums/QueueTypeEum";

/** The API */
const queueApi = new QueueApi(DEFAULT_OPTIONS, config.apiUri);

/** Common DLQ api fields used across various operations */
export type DeadLetterQueueCommonFields = {
  /** The name of the queue on which we are operating */
  queueType: QueueTypeEnum;
  /** The AWS region containing the queue */
  region: string;
};

/** Fields specific to the fetch API operation */
export type DeadLetterQueueFetchFields = DeadLetterQueueCommonFields & {
  /** The length of time to Poll the API in seconds */
  pollingDurationSeconds: number;
};

/** Fields specific to the delete API operation */
export type DeadLetterQueueDeleteFields = {
  /** The array of receiptHandles to delete */
  queueItems: { receiptHandle: string }[];
};

/**
 * State to handle items that have been deleted inbetween fetch requests
 */
export const remainingDeadLetterQueueState = atom<{ queueItem: QueueItem; failedDeletionMessage?: string }[] | null>(
  null,
);

/** State to store most recent query parameters */
const deadLetterQueryValuesAtom = atom<{
  region: string;
  queueType: QueueTypeEnum;
}>({ region: config.activeRegions[0], queueType: QueueTypeEnum.IntegrationFilter });

/**
 * Setter for the Dead Letter Queue Fetch API
 * @param [field, idToken]
 */
export const fetchDeadLetterQueueState = atom(null, async (get, set, fields: DeadLetterQueueFetchFields) => {
  const accessToken = get(accessTokenAtom);
  if (fields !== null) {
    try {
      const pollingDurationMiliseconds = fields.pollingDurationSeconds
        ? fields.pollingDurationSeconds * 1000
        : undefined;

      const { data } = await queueApi.deadLetterQuery(fields.queueType, pollingDurationMiliseconds, fields.region, {
        headers: { Authorization: accessToken },
      });

      /*
       * Admin API nests failured messages on a per region basis, however this UI is currently only set up to handle one region.
       * This checks if the region we have queried failed, and throws accordingly
       */
      if (data !== undefined && Object.values(data)[0]?.errorMessage) {
        throw new Error(Object.values(data)[0].errorMessage);
      }

      const fetched = Object.values(data)[0]?.queueItems || [];

      // Store current query in state
      set(deadLetterQueryValuesAtom, fields);

      // Store result in state
      set(
        remainingDeadLetterQueueState,
        fetched.map((queueItem) => {
          return {
            queueItem,
          };
        }),
      );
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleError(error as Error, loadableFetchDeadLetterQueueState, fields);
      throw error;
    }
  }
});

/**
 * Setter for the Dead Letter Queue Delete API
 * @param [field, idToken]
 */
export const deleteDeadLetterQueueState = atom(null, async (get, set, fields: DeadLetterQueueDeleteFields) => {
  const accessToken = get(accessTokenAtom);
  if (fields !== null) {
    try {
      const queryParameters = get(deadLetterQueryValuesAtom);

      const { data } = await queueApi.deadLetterDelete(
        queryParameters.queueType,
        queryParameters.region,
        { queueItems: { [queryParameters.region]: fields.queueItems } },
        {
          headers: { Authorization: accessToken },
        },
      );
      /*
       * Admin API nests failured messages on a per region basis, however this UI is currently only set up to handle one region.
       * This checks if the region we have queried failed, and throws accordingly
       */
      if (data !== undefined && Object.values(data)[0]?.errorMessage) {
        throw new Error(Object.values(data)[0].errorMessage);
      }

      // Filter state to remove any deleted items
      const existingState = get(remainingDeadLetterQueueState) || [];
      const deletedItems = Object.values(data)[0]?.deletedQueueItems || [];
      const deleteFailures = Object.values(data)[0]?.deleteFailures || [];

      const remainingItems = existingState
        // remove deleted items from array
        .filter(
          (item) => !deletedItems.some((deletedItem) => item.queueItem.receiptHandle === deletedItem.receiptHandle),
        )
        // wrap with failed deletion message if one exists
        .map((item) => {
          const message = deleteFailures.find((deleteItem) => {
            return item.queueItem.receiptHandle === deleteItem.receiptHandle;
          })?.message;

          return {
            queueItem: item.queueItem,
            failedDeletionMessage: message ? `Delete failure: ${message}` : undefined,
          };
        });

      // Store updated queue items list in state
      set(remainingDeadLetterQueueState, remainingItems);

      const statusString = `Delete queue items operation ${deleteFailures.length > 0 ? "partially " : ""}complete.${
        deleteFailures.length > 0 ? ` ${deleteFailures.length} item(s) failed to delete.` : ""
      }`;

      enqueueSnackbar(statusString, {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleError(error as Error, loadableDeleteDeadLetterQueueState, fields);
      const existingState = get(remainingDeadLetterQueueState) || [];

      const itemsWithError = existingState
        // wrap with failed deletion message
        .map((item) => ({
          queueItem: item.queueItem,
          failedDeletionMessage: `Delete failure: ${(error as Error).message}`,
        }));

      // Store updated queue items list in state
      set(remainingDeadLetterQueueState, itemsWithError);
    }
  }
});

/**
 * Setter for the Dead Letter Queue Redrive API
 * @param [field, idToken]
 */
export const redriveDeadLetterQueueState = atom(null, async (get, set, fields: DeadLetterQueueCommonFields) => {
  const accessToken = get(accessTokenAtom);

  if (fields !== null) {
    try {
      const { data } = await queueApi.deadLetterRedrive(fields.queueType, fields.region, {
        headers: { Authorization: accessToken },
      });

      /*
       * Admin API nests failured messages on a per region basis, however this UI is currently only set up to handle one region.
       * This checks if the region we have queried failed, and throws accordingly
       */
      if (data !== undefined && Object.values(data)[0]?.errorMessage) {
        throw new Error(Object.values(data)[0].errorMessage);
      }

      // Reset remaining dead letter queues state
      set(remainingDeadLetterQueueState, null);

      const statusString = `Redrive ${fields.queueType} queue operation for ${fields.region} complete.`;

      enqueueSnackbar(statusString, {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleError(error as Error, loadableRedriveDeadLetterQueueState, fields);
      const existingState = get(remainingDeadLetterQueueState) || [];

      const remainingItems = existingState
        // wrap with failed deletion message
        .map((item) => ({
          queueItem: item.queueItem,
          failedDeletionMessage: `Redrive failure: ${(error as Error).message}`,
        }));

      // Store updated queue items list in state
      set(remainingDeadLetterQueueState, remainingItems);
    }
  }
});

/**
 * Setter for the Dead Letter Queue Purge API
 * @param [field, idToken]
 */
export const purgeDeadLetterQueueState = atom(null, async (get, set, fields: DeadLetterQueueCommonFields) => {
  const accessToken = get(accessTokenAtom);

  if (fields !== null) {
    try {
      const { data } = await queueApi.deadLetterPurge(fields.queueType, fields.region, {
        headers: { Authorization: accessToken },
      });

      /*
       * Admin API nests failured messages on a per region basis, however this UI is currently only set up to handle one region.
       * This checks if the region we have queried failed, and throws accordingly
       */
      if (data !== undefined && Object.values(data)[0]?.errorMessage) {
        throw new Error(Object.values(data)[0].errorMessage);
      }

      // Reset remaining dead letter queues state
      set(remainingDeadLetterQueueState, null);

      const statusString = `Purge ${fields.queueType} queue operation for ${fields.region} complete.`;
      enqueueSnackbar(statusString, {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleError(error as Error, loadablePurgeDeadLetterQueueState, fields);
      const existingState = get(remainingDeadLetterQueueState) || [];
      const remainingItems = existingState
        // wrap with failed deletion message
        .map((item) => ({
          queueItem: item.queueItem,
          failedDeletionMessage: `Purge failure: ${(error as Error).message}`,
        }));

      // Store updated queue items list in state
      set(remainingDeadLetterQueueState, remainingItems);
    }
  }
});

/** Loadable setter for fetchDeadLetterQueueState */
export const loadableFetchDeadLetterQueueState = loadableSet(fetchDeadLetterQueueState);
/** Loadable setter for deleteDeadLetterQueueState */
export const loadableDeleteDeadLetterQueueState = loadableSet(deleteDeadLetterQueueState);
/** Loadable setter for redriveDeadLetterQueueState */
export const loadableRedriveDeadLetterQueueState = loadableSet(redriveDeadLetterQueueState);
/** Loadable setter for purgeDeadLetterQueueState */
export const loadablePurgeDeadLetterQueueState = loadableSet(purgeDeadLetterQueueState);
