/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/**
 * A simple utility to check if an object is null, undefined or empty, i.e. `{}`
 * @returns Whether an object is undefined or empty
 */
export function objectIsEmpty(object: object): boolean {
  if (object !== undefined || object !== null) {
    return Object.keys(object).length === 0;
  } else {
    return true;
  }
}
