/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/**
 * This method will parse an item to a JSON object
 * @param item The item that needs to be parsed to a JSON object
 * @returns The JSON object
 */
export function tryParseJSON(item: string): object | undefined {
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(`Unable to parse Json error: ${error}`);
    return undefined;
  }
}
