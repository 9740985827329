/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/** Optional operation to perform error update or delete action */
export enum Operation {
  /** Delete or clear the errors */
  DELETE = "DELETE",
  /** Update the errors for reprocessing */
  UPDATE = "UPDATE",
}
