import { JSONEditor, JSONEditorPropsOptional, Mode } from "vanilla-jsoneditor";
import { useEffect, useRef } from "react";

export function JsonEditor(props: JSONEditorPropsOptional) {
  const refContainer = useRef<HTMLDivElement>(null);
  const refEditor = useRef<JSONEditor | null>(null);

  useEffect(() => {
    // create editor
    if (refContainer.current) {
      refEditor.current = new JSONEditor({
        target: refContainer.current,
        props: {
          ...props,
          mode: Mode.text,
        },
      });

      return () => {
        // destroy editor
        if (refEditor.current) {
          refEditor.current.destroy();
          refEditor.current = null;
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps({
        ...props,
        mode: Mode.text,
      });
    }
  }, [props]);

  return <div style={{ display: "flex", height: "100%" }} ref={refContainer}></div>;
}
