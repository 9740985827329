/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement } from "react";
import { DocumentQueryStatusResponse, ExecutionStatusEnum } from "@sophos-socos/admin-api-client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import { Box } from "@mui/material";

/** Props for the region response table */
type RegionStatusResponseProperties = {
  /** The response data */
  data: { [region: string]: DocumentQueryStatusResponse };
};

/**
 * Response table component to render the region response data status
 * @returns Region status response component
 */
export function RegionStatusResponseTable(props: RegionStatusResponseProperties): ReactElement {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="region-status-response">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: "6rem" }}>Region</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Error message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.data).map(([region, countStatusResponse]) => (
            <TableRow key={region} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {region}
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {countStatusResponse.status === ExecutionStatusEnum.Failure ? (
                    <Error color="error" sx={{ pr: 1 }} />
                  ) : (
                    <CheckCircle color="success" sx={{ pr: 1 }} />
                  )}
                  {countStatusResponse.status}
                </Box>
              </TableCell>
              <TableCell>{countStatusResponse.errorMessage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
