/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { useEffect, useMemo, useState, type ReactElement } from "react";
import { useAtom, useSetAtom } from "jotai";
import { useFieldArray, useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import { ContentCopy } from "@mui/icons-material";
import { config } from "../../../config/AdminUiConfig";
import { LoadingButton } from "../../../components/LoadingButton/LoadingButton";
import { ControlledTextField } from "../../../components/HookForms/ControlledTextField";
import {
  FetchPredictedThreatMappingFields,
  fetchPredictedThreatMappingFields,
  loadableFetchPredictiveThreatMappingState,
} from "../../../services/predictedThreatMappingsApi";
import {
  DocumentQueryResponse,
  PredictedThreatMappingsResponse,
  SourceSystemPredictiveThreatMapping,
} from "@sophos-socos/admin-api-client";
import { RegionStatusResponseTable } from "../RegionStatusResponseTable";
import { ALL_REGIONS_KEY } from "../../../helpers/AllRegionsKey";
import { formatThreatMappings } from "../../../helpers/formatThreatMappings";
import { enqueueSnackbar } from "notistack";
import { ControlledJsonEditor } from "../../../components/HookForms/ControlledJsonEditor";

/**
 * Threat mapping view component to display the predicted threat mappings
 * @returns Predicted Threat mapping view component
 */
export function PredictedThreatMappingsView(): ReactElement {
  const [result] = useAtom(loadableFetchPredictiveThreatMappingState);
  const dispatch = useSetAtom(fetchPredictedThreatMappingFields);
  const [itemCount, setItemCount] = useState(0);
  const [queriedDates, setQueriedDates] = useState<{ afterDate: string | undefined; beforeDate: string | undefined }>({
    afterDate: undefined,
    beforeDate: undefined,
  });

  const threatMappingsData = result.state === "hasData" ? result.data : undefined;
  const threatMappingsResponse = useMemo(
    () => (threatMappingsData ? threatMappingsData : { sourceSystemThreatMappings: [] }),
    [threatMappingsData],
  );
  // typecast is due to a limitation of typescript when merging additional fields in the openapi client
  const { sourceSystemThreatMappings: _, ...regionStatusData } =
    threatMappingsResponse as PredictedThreatMappingsResponse & DocumentQueryResponse;

  /** Create form to control fields for request */
  const { control, handleSubmit, setValue } = useForm<FetchPredictedThreatMappingFields>({
    defaultValues: {
      region: ALL_REGIONS_KEY,
      afterDate: "",
      beforeDate: "",
    },
  });

  /** Create form to control json editor values for copying to clipboard */
  const {
    control: jsonEditorControl,
    setValue: setJsonEditorValue,
    getValues: getJsonEditorValues,
    clearErrors: clearJsonEditorErrors,
  } = useForm<{
    items: (SourceSystemPredictiveThreatMapping & { json: string })[];
  }>();

  const { fields } = useFieldArray({ control: jsonEditorControl, name: "items" });

  /* Auto fill latest date after a query is performed */
  useEffect(() => {
    const allThreatMappings = threatMappingsResponse.sourceSystemThreatMappings.flatMap(
      (object) => object.threatMappings,
    );
    const latestDateAdded = allThreatMappings.reduce((maxDate: string | undefined, object) => {
      if (maxDate !== undefined) {
        const date = new Date(object.dateAdded);
        return date > new Date(maxDate) ? object.dateAdded : maxDate;
      } else {
        return object.dateAdded;
      }
    }, undefined);

    setValue("afterDate", latestDateAdded);

    setItemCount(allThreatMappings.length);

    setJsonEditorValue(
      "items",
      threatMappingsResponse.sourceSystemThreatMappings.map((value) => ({
        ...value,
        json: value.threatMappings !== undefined ? formatThreatMappings(value.threatMappings) : "[]",
      })),
    );
  }, [threatMappingsResponse.sourceSystemThreatMappings, setValue, setJsonEditorValue]);

  /* Dispatch field updates to trigger fetch of threat mappings */
  const handleFetchThreatMappings = (data: FetchPredictedThreatMappingFields) => {
    dispatch({ ...data, region: data.region === ALL_REGIONS_KEY ? undefined : data.region });
    setQueriedDates({ afterDate: data.afterDate, beforeDate: data.beforeDate });
  };

  /* Parse, format, and copy threat mappings to clipboard */
  const copyMappingsToClipboard = (index: number, formatMappings = false) => {
    try {
      const editableThreatMappings = getJsonEditorValues(`items.${index}.json`);

      // parse and format threat mappings
      const parsedThreatMappings = JSON.parse(editableThreatMappings);

      // reformat to only include required properties
      const formattedThreatMappings = formatMappings
        ? parsedThreatMappings.map((threatMapping: unknown) => {
            if (!threatMapping || typeof threatMapping !== "object")
              throw new Error("Could not copy: Invalid threat mappings format");
            const formattedThreatMapping = {
              dateAdded: "dateAdded" in threatMapping ? threatMapping.dateAdded : "",
              alertType: "alertType" in threatMapping ? threatMapping.alertType : "",
              threatId: "threatId" in threatMapping ? threatMapping.threatId : "",
              threatName: "threatName" in threatMapping ? threatMapping.threatName : "",
              threatType: "threatType" in threatMapping ? threatMapping.threatType : "",
              threatLevel: "threatLevel" in threatMapping ? threatMapping.threatLevel : "",
              comment: "comment" in threatMapping ? threatMapping.comment : "",
              "mitreAtt&ckVersion": "mitreAtt&ckVersion" in threatMapping ? threatMapping["mitreAtt&ckVersion"] : "",
            };
            return formattedThreatMapping;
          })
        : parsedThreatMappings;

      // write formatted results to the clipboard
      navigator.clipboard.writeText(JSON.stringify(formattedThreatMappings, null, 2));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar((error as Error).message, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      } else {
        enqueueSnackbar("An unknown error occurred.", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Grid height="100%" container direction="column">
        <Grid item xs={1}>
          <Grid container direction="row">
            <Card sx={{ width: "100%", mt: 4, pr: 2, pb: 1 }} component="section">
              <Box sx={{ m: 3, mb: 1 }} display={"flex"} alignItems={"center"} flexDirection={"row"} width={"100%"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="h6">Get predicted Threat Mappings</Typography>
                </Box>
              </Box>
              <CardContent>
                <Grid container direction={"column"}>
                  <Grid item sm={2}>
                    <ControlledTextField
                      id="region-select"
                      select
                      control={control}
                      sx={{ m: 1, minWidth: "5rem" }}
                      label="Region"
                      name="region"
                      rules={{
                        required: true,
                      }}
                    >
                      {[
                        <MenuItem key={ALL_REGIONS_KEY} value={ALL_REGIONS_KEY}>
                          All regions
                        </MenuItem>,
                        ...config.activeRegions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )),
                      ]}
                    </ControlledTextField>
                    <ControlledTextField
                      id="after-date"
                      label="After date"
                      control={control}
                      name="afterDate"
                      sx={{ m: 1 }}
                      rules={{
                        required: false,
                        pattern: {
                          value: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?/,
                          message: "Value must be ISO date string",
                        },
                      }}
                    />
                    <ControlledTextField
                      id="before-date"
                      label="Before date"
                      control={control}
                      name="beforeDate"
                      sx={{ m: 1 }}
                      rules={{
                        required: false,
                        pattern: {
                          value: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?/,
                          message: "Value must be ISO date string",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box sx={{ m: 1, mt: 0 }} display="flex" flexDirection="row" justifyContent="flex-end">
                <LoadingButton
                  state={result.state}
                  onClick={handleSubmit((data) => handleFetchThreatMappings(data))}
                  variant="contained"
                >
                  Get Predicted Threat Mappings
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          {threatMappingsData ? (
            <>
              {regionStatusData ? (
                <Card sx={{ mt: 4 }} component="section">
                  <RegionStatusResponseTable data={regionStatusData} />
                </Card>
              ) : null}
              <Card sx={{ mt: 4, p: 2 }} component="section">
                <Typography variant="subtitle2">
                  Found {itemCount} predicted threat mappings between the date range: afterDate{" "}
                  {queriedDates.afterDate ? queriedDates.afterDate : "ANY"} and beforeDate{" "}
                  {queriedDates.beforeDate ? queriedDates.beforeDate : "ANY"}
                </Typography>
              </Card>
              {fields.map((sourceSystemMapping, index) => (
                <Card key={sourceSystemMapping.id} sx={{ mt: 4 }} component="section">
                  <CardContent>
                    <Box display="flex" alignItems="center" sx={{ justifyContent: "space-between", mb: "1rem" }}>
                      <Typography id={`source-system-${index}`} variant="h6">
                        {sourceSystemMapping.sourceSystem}
                      </Typography>
                      <Box display="flex" gap="1rem">
                        <Button variant="contained" size="small" onClick={() => copyMappingsToClipboard(index)}>
                          <ContentCopy fontSize="small" sx={{ mr: "0.5rem" }} />
                          Copy Raw
                        </Button>
                        <Button variant="contained" size="small" onClick={() => copyMappingsToClipboard(index, true)}>
                          <ContentCopy fontSize="small" sx={{ mr: "0.5rem" }} />
                          Copy Formatted
                        </Button>
                      </Box>
                    </Box>
                    <div id={`threat-mappings-${index}`}>
                      <ControlledJsonEditor
                        control={jsonEditorControl}
                        name={`items.${index}.json`}
                        clearErrors={clearJsonEditorErrors}
                        askToFormat={false}
                      />
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : result.state === "hasError" ? (
            <Box display="flex" justifyContent="center">
              <Box display="flex" alignItems="center">
                <ErrorIcon color="error" sx={{ m: 2 }} />
                Failed to get threat mappings
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}
