/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement } from "react";
import { ExecutionCountResponse, ExecutionStatusEnum } from "@sophos-socos/admin-api-client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import { Box } from "@mui/material";
import numbro from "numbro";

/** Props for the execution count response table */
type ExecutionCountResponseTable = {
  /** The response data */
  data: ExecutionCountResponse;
};

/**
 * Response count table component to render the response data
 * @returns Response count table component
 */
export function ExecutionCountResponseTable(props: ExecutionCountResponseTable): ReactElement {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="execution-count-response">
        <TableHead>
          <TableRow>
            <TableCell>Region</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Error message</TableCell>
            <TableCell align="right">Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.data).map(([region, countStatusResponse]) => (
            <TableRow key={region} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {region}
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {countStatusResponse.status === ExecutionStatusEnum.Failure ? (
                    <Error color="error" sx={{ pr: 1 }} />
                  ) : (
                    <CheckCircle color="success" sx={{ pr: 1 }} />
                  )}
                  {countStatusResponse.status}
                </Box>
              </TableCell>
              <TableCell>{countStatusResponse.errorMessage}</TableCell>
              <TableCell align="right">
                {countStatusResponse.count !== undefined
                  ? numbro(countStatusResponse.count).format({ thousandSeparated: true })
                  : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
