/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { Container, Typography } from "@mui/material";
import { type ReactElement } from "react";

/**
 * Root home component for a 404 or route not found
 * @returns Root home component
 */
export function NoMatch(): ReactElement {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" marginTop={2} gutterBottom>
        404 - No match found for this route
      </Typography>

      <Typography paragraph={true} marginTop={2} gutterBottom>
        Sorry the route doesn&apos;t exist.
      </Typography>
    </Container>
  );
}
