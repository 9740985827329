/*
 * Copyright 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { MenuItem, Pagination, TextField } from "@mui/material";
import { useState, type ReactElement, useEffect } from "react";
import Box from "@mui/material/Box";
import { ResizablePanel } from "../../components/ResizablePanel/ResizablePanel";
import { ControlledJsonEditor } from "../../components/HookForms/ControlledJsonEditor";
import { QueryErrorFields, queryErrorFields, queryErrorState } from "../../services/queryErrorApi";
import { LoadingButton } from "../../components/LoadingButton/LoadingButton";
import { objectIsEmpty } from "../../helpers/object";
import { useAtom, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { ExecutionErrorResponse } from "./ExecutionErrorResponse";
import Error from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import { ControlledTextField } from "../../components/HookForms/ControlledTextField";
import { config } from "../../config/AdminUiConfig";
import { visuallyHidden } from "@mui/utils";
import { useCachedForm } from "../../helpers/useCachedForm";

/**
 * Query error view component to display the query results
 * @returns Query error view component
 */
export function QueryErrorView(): ReactElement {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(page);
  const [result] = useAtom(loadable(queryErrorState(page)));
  const [isMany, setIsMany] = useState(false);
  const dispatch = useSetAtom(queryErrorFields);

  useEffect(() => {
    if (result.state === "hasData" && result.data) {
      const resultObject = Object.values(result.data)?.[0]?.result;
      if (Array.isArray(resultObject) && resultObject.length > 1) {
        setIsMany(true);
        if (isMany && resultObject.length === config.queryErrorPageSize && page === pageCount) {
          setPageCount(pageCount + 1);
        }
      }
    }
  }, [isMany, page, pageCount, result]);

  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useCachedForm<QueryErrorFields>(
    {
      defaultValues: {
        region: config.activeRegions?.[0],
        query: "[]",
      },
    },
    "CACHE_QUERY_ERROR",
  );

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Querying Business Errors
      </Typography>
      <Typography variant="subtitle1" color="GrayText" gutterBottom>
        Enter a Mongo query below i.e {'[{ "$match": { "alertId": "d877acfc-d695-440b-87f6-03c1b2f1fe20" }}]'}
      </Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <ResizablePanel initialSize={300}>
          <ControlledJsonEditor control={control} clearErrors={clearErrors} name="query" />
        </ResizablePanel>

        {/* This is a simple text input for screen readers */}
        <Box sx={visuallyHidden}>
          <TextField {...register("query")} label="Query" aria-errormessage="query-error" required />
        </Box>

        <Box display="flex" marginLeft="auto" marginTop={2}>
          <ControlledTextField
            id="region-select"
            select
            control={control}
            label="Region"
            name="region"
            rules={{
              required: true,
            }}
          >
            {config.activeRegions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </ControlledTextField>

          <LoadingButton
            state={result.state}
            sx={{ ml: 2 }}
            variant="contained"
            disabled={!objectIsEmpty(errors)}
            onClick={handleSubmit((data) => {
              setPage(0);
              setPageCount(1);
              dispatch(data);
              setIsMany(false);
            })}
          >
            Query Errors
          </LoadingButton>
        </Box>
      </Box>

      {result.state === "hasData" && result.data && Object.values(result.data)[0]?.result ? (
        <div>
          <Typography variant="h4" gutterBottom>
            Query Results
          </Typography>
          <Box display="flex" marginLeft="auto" marginTop={2}>
            <ResizablePanel id="error-results" initialSize={300} aria-hidden="true">
              <ExecutionErrorResponse data={result.data} />
            </ResizablePanel>
          </Box>
          {isMany && (
            <Box display="flex" marginLeft="auto" marginTop={2}>
              <Pagination
                data-testid="error"
                count={pageCount + 1}
                page={page + 1}
                onChange={(event, value) => {
                  setPage(value - 1);
                }}
              />
            </Box>
          )}
        </div>
      ) : result.state === "hasData" && result.data && Object.values(result.data)[0]?.errorMessage ? (
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Error color="error" sx={{ pr: 1 }} />
            {Object.values(result.data)[0]?.errorMessage}
          </Box>
        </Box>
      ) : result.state === "hasError" ? (
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Error color="error" sx={{ pr: 1 }} />
            Failed to query errors
          </Box>
        </Box>
      ) : result.state === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
    </Container>
  );
}
