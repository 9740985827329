/*
 * Copyright 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { useEffect } from "react";
import { DeepPartial, FieldValues, UseFormProps, useForm } from "react-hook-form";

/**
 * This hook will provide a form that stores changed values in session storage
 * @param formProps The props used by the useForm hook
 * @param cacheKey A unique key to identify where to store/load form values
 * @param [reviver] A reviver function for JSON.parse, if values need to be processed on loading
 * @returns The form, as returned by react-hook-form
 */
export const useCachedForm = <T extends FieldValues>(
  formProps: UseFormProps<T, unknown>,
  cacheKey: string,
  reviver?: ((this: unknown, key: string, value: unknown) => unknown) | undefined,
) => {
  /* Load default values from session storage */
  const getInitialValues = () => {
    try {
      const storedValues = sessionStorage.getItem(cacheKey);
      const initialValues: DeepPartial<T> | undefined = storedValues
        ? { ...formProps.defaultValues, ...JSON.parse(storedValues, reviver) }
        : formProps.defaultValues;
      return initialValues;
    } catch {
      return formProps.defaultValues;
    }
  };

  /* Initialise form */
  const form = useForm<T>({ ...formProps, defaultValues: getInitialValues() });

  /* Update session storage when values update */
  const values = form.watch();
  useEffect(() => {
    values && sessionStorage.setItem(cacheKey, JSON.stringify(values));
  }, [cacheKey, values]);

  return form;
};
