/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import React from "react";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { Controller, Control, RegisterOptions, Path, FieldValues } from "react-hook-form";

/** Props for the controlled switch field */
type ControlledSwitchProps<TFieldValues extends FieldValues> = {
  /** React hooks control */
  control: Control<TFieldValues>;
  /** The name of the field */
  name: Path<TFieldValues>;
  /** Validation rules */
  rules?: RegisterOptions;
  /** Switch label */
  label?: string;
} & SwitchProps;

/**
 * Controlled switch for react hook forms
 * @template TFieldValues
 * @param props The switch props
 * @returns Controlled switch field
 */
export function ControlledSwitch<TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: ControlledSwitchProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <FormControlLabel
          control={
            <Switch
              {...props}
              ref={ref}
              onChange={(e) => {
                onChange(e.target.checked as any);
              }}
              checked={value}
            />
          }
          label={props.label}
        />
      )}
    />
  );
}
