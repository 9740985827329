/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./views/Home/Home";
import { Layout } from "./views/Layout/Layout";
import { NoMatch } from "./views/NoMatch/NoMatch";
import { UpdateErrorView } from "./views/UpdateErrorView/UpdateErrorView";
import { QueryErrorView } from "./views/QueryErrorView/QueryErrorView";
import { DeadLetterQueueView } from "./views/DeadLetterQueueView/DeadLetterQueueView";
import { IngestAlertView } from "./views/IngestAlertView/IngestAlertView";
import { RulesHitByOrgView } from "./views/FilterStatistics/RulesHitByOrgView/RulesHitByOrgView";
import { OrgsThatHitRuleView } from "./views/FilterStatistics/OrgsThatHitRuleView/OrgsThatHitRuleView";
import { MostCommonRulesView } from "./views/FilterStatistics/MostCommonRulesView/MostCommonRulesView";
import { FailedThreatMappingsView } from "./views/ThreatMappings/FailedThreatMappingsView/FailedThreatMappingsView";
import { PredictedThreatMappingsView } from "./views/ThreatMappings/PredictedThreatMappingsView/PredictedThreatMappingsView";

function AppRoutes(): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="reprocessing" element={<UpdateErrorView />} />
        <Route path="query" element={<QueryErrorView />} />
        <Route path="deadletterqueue" element={<DeadLetterQueueView />} />
        <Route path="ingestalert" element={<IngestAlertView />} />
        
        <Route path="threatmappings/failedthreatmappings" element={<FailedThreatMappingsView />} />
        <Route path="threatmappings/predictedthreatmappings" element={<PredictedThreatMappingsView />} />

        <Route path="filterstatistics/hitrules" element={<RulesHitByOrgView />} />
        <Route path="filterstatistics/ruleorganisations" element={<OrgsThatHitRuleView />} />
        <Route path="filterstatistics/mostcommonrules" element={<MostCommonRulesView />} />

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
