/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/* Enum for currently selected alert metadata input method when ingesting alerts */
export enum MetadataInputMethod {
  /* Use the form field input values */
  FORM = "FORM",
  /* Use the JSON input values */
  JSON = "JSON",
}
