/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

import {
  Controller,
  Control,
  RegisterOptions,
  UseFormClearErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { JsonEditor } from "../JsonEditor/JsonEditor";
import { Content, ContentErrors, ContentParseError, JSONContent, JSONEditorPropsOptional } from "vanilla-jsoneditor";

/**
 * Determines whether JSONEditor Content is JSONContent or TextContent
 * @param maybeJSONContent The Content
 * @returns whether the input is JSONContent
 */
function isJSONContent(maybeJSONContent: Content): maybeJSONContent is JSONContent {
  return (maybeJSONContent as JSONContent).json !== undefined;
}

/**
 * Determines whether JSONEditor ContentErrors is ContentParseError or ContentValidationErrors
 * @param maybeJSONContent The ContentErrors
 * @returns whether the input is ContentParseError
 */
function isContentParseError(maybeContentParseError: ContentErrors): maybeContentParseError is ContentParseError {
  return (maybeContentParseError as ContentParseError).parseError !== undefined;
}

/** Props for the controlled json editor */
type ControlledJsonEditorProps<TFieldValues extends FieldValues> = {
  /** React hooks control */
  control: Control<TFieldValues>;
  /** Clear errors from the useForm hook */
  clearErrors: UseFormClearErrors<TFieldValues>;
  /** The name of the field */
  name: Path<TFieldValues>;
  /** Validation rules */
  rules?: RegisterOptions;
  /** Default value */
  defaultValue?: string;
} & JSONEditorPropsOptional;

/**
 * Controlled json editor for react hook forms
 * @template TFieldValues
 * @param props The json editor props
 * @returns Controlled json editor
 */
export function ControlledJsonEditor<TFieldValues extends FieldValues>({
  control,
  clearErrors,
  name,
  rules,
  defaultValue,
  ...props
}: ControlledJsonEditorProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue as PathValue<TFieldValues, Path<TFieldValues>>}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <JsonEditor
            {...props}
            onBlur={onBlur}
            onChange={(content, _, status) => {
              // Set the value
              if (!isJSONContent(content)) {
                onChange(content.text as PathValue<TFieldValues, Path<TFieldValues>>);
              }

              // Deal with error state
              const contentErrors = status.contentErrors;
              if (contentErrors === null) {
                clearErrors(name);
              } else {
                if (isContentParseError(contentErrors)) {
                  control.setError(name, { message: contentErrors.parseError.message });
                } else {
                  control.setError(name, { message: contentErrors.validationErrors[0]?.message || "Validation error" });
                }
              }
            }}
            content={{ text: value !== undefined ? value : "{}" }}
            askToFormat={true}
          />
        );
      }}
    />
  );
}
