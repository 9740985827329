/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Controller, Control, RegisterOptions, Path, FieldValues } from "react-hook-form";

/** Props for the controlled text field */
type ControlledTextFieldProps<TFieldValues extends FieldValues> = {
  /** React hooks control */
  control: Control<TFieldValues>;
  /** The name of the field */
  name: Path<TFieldValues>;
  /** Validation rules */
  rules?: RegisterOptions;
} & TextFieldProps;

/**
 * Controlled text field for react hook forms
 * @template TFieldValues
 * @param props The text field props
 * @returns Controlled text field
 */
export function ControlledTextField<TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  ...props
}: ControlledTextFieldProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error} }) => (
        <TextField
          {...props}
          ref={ref}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value as any)}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
}