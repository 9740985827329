/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { Container, Typography } from "@mui/material";
import { type ReactElement } from "react";

/**
 * A home page for the app
 * @returns The home page
 */
export function Home(): ReactElement {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" marginTop={2} gutterBottom>
        Welcome to the SOC.OS Admin Console
      </Typography>

      <Typography paragraph={true} marginTop={2} gutterBottom>
        For now this is mostly placeholder information. Come back later and I&apos;m sure we&apos;ll have more useful
        information and things to do.
      </Typography>
    </Container>
  );
}
