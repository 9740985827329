/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { WritableAtom, atom } from "jotai";

const cache1 = new WeakMap();
const memo1 = <T>(create: () => T, dep1: object): T =>
  (cache1.has(dep1) ? cache1 : cache1.set(dep1, create())).get(dep1);

/** Possible loading states */
export type LoadableSetter<Value> =
  | { state: "initial" }
  | { state: "loading" }
  | { state: "hasError"; error: unknown }
  | { state: "complete"; data: Value };

/** Add loading states to a Setter atom */
export function loadableSet<Value, Args extends unknown[], Result>(
  anAtom: WritableAtom<Value, Args, Result>,
): WritableAtom<LoadableSetter<Value>, Args, void> {
  return memo1(() => {
    const loadableCache = new WeakMap<Promise<void>, LoadableSetter<Value>>();
    const loadingStateAtom = atom<LoadableSetter<Value>>({ state: "initial" });

    if (import.meta.env?.MODE !== "production") {
      loadingStateAtom.debugPrivate = true;
    }

    const derivedAtom = atom(
      (get) => get(loadingStateAtom),
      (get, set, ...args: Args) => {
        let setter: Result | Promise<Result>;
        try {
          setter = set(anAtom, ...args);
        } catch (error) {
          set(loadingStateAtom, { state: "hasError", error });
          return;
        }
        if (!(setter instanceof Promise)) {
          set(loadingStateAtom, { state: "complete", data: get(anAtom) });
          return;
        }
        const promise = setter;
        const cached = loadableCache.get(promise);
        if (cached) {
          set(loadingStateAtom, cached);
          return;
        }
        set(loadingStateAtom, { state: "loading" });
        promise.then(
          () => {
            const completeLoadingState: LoadableSetter<Value> = { state: "complete", data: get(anAtom) };
            loadableCache.set(promise, completeLoadingState);
            set(loadingStateAtom, completeLoadingState);
          },
          (error) => {
            const errorLoadingState: LoadableSetter<Value> = { state: "hasError", error };
            loadableCache.set(promise, errorLoadingState);
            set(loadingStateAtom, errorLoadingState);
          },
        );
      },
    );

    if (import.meta.env?.MODE !== "production") {
      derivedAtom.debugPrivate = true;
    }

    return derivedAtom;
  }, anAtom);
}
