/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
export enum QueueTypeEnum {
  LogCollectorFilter = "LOG_COLLECTOR_FILTER",
  IntegrationFilter = "INTEGRATION_FILTER",
  RetryFilter = "RETRY_FILTER",
  RetryProcessor = "RETRY_PROCESSOR",
  Parse = "PARSE",
  Correlate = "CORRELATE",
  ClusterPersist = "CLUSTER_PERSIST",
  Process = "PROCESS",
}
