/*
 * Copyright 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { PredictiveThreatMapping, ThreatMapping } from "@sophos-socos/admin-api-client";

/**
 * Formats threat mappings
 *
 * Converts ';'mitreAttackVersion' to 'mitreAtt&ckVersion' (with the special '&' character) for conforming with mapping configuration.
 * Formats JSON using stringify options
 * @param threatMappings Threat mappings response
 * @returns Mapped and formatted threat mappings
 */
export function formatThreatMappings(threatMappings: (ThreatMapping | PredictiveThreatMapping)[]) {
    const mappedMitreVersionNames = threatMappings.map((threatMapping) => {
      const { mitreAttackVersion, ...baseThreatMapping } = threatMapping;
      const mappedThreatMapping = { ...baseThreatMapping, "mitreAtt&ckVersion": mitreAttackVersion };
      return mappedThreatMapping;
    });
  
    return JSON.stringify(mappedMitreVersionNames, null, 2);
  }