/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { type Configuration } from "@sophos-socos/admin-api-client";

/** The default options for the API */
export const DEFAULT_OPTIONS: Configuration = {
  isJsonMime: (): boolean => false,
  baseOptions: { origin: "*" },
};
