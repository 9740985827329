/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { ThreatMappingsApi } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { refreshAtom } from "../helpers/atoms";
import { handleError } from "./apiError";
import { accessTokenAtom } from "./userService";

/** The API */
const threatMappingsApi = new ThreatMappingsApi(DEFAULT_OPTIONS, config.apiUri);

/** The fetch threat mapping fields */
export type FetchPredictedThreatMappingFields = {
  /* Desired region to query, leave blank for all regions */
  region?: string;
  /* After date to limit query results */
  afterDate?: string;
  /* Before date to limit query results */
  beforeDate?: string;
};

/** The request input fields */
export const fetchPredictedThreatMappingFields = atom<null | FetchPredictedThreatMappingFields>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the fetch predictive threat mapping API
 * @param [field, idToken]
 */
const fetchPredictiveThreatMappingState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(fetchPredictedThreatMappingFields);

  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      const { data } = await threatMappingsApi.fetchPredictedThreatMappings(
        fields.region,
        fields.afterDate?.length === 0 ? undefined : fields.afterDate,
        fields.beforeDate?.length === 0 ? undefined : fields.beforeDate,
        {
          headers: { Authorization: accessToken },
        },
      );
      return data;
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/** Loadable atoms */
export const loadableFetchPredictiveThreatMappingState = loadable(fetchPredictiveThreatMappingState);
