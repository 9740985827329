/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { type ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";

/** List link item props */
interface ListItemLinkProps {
  /** Optional icon to display */
  icon?: React.ReactElement;
  /** Primary text */
  primary: string;
  /** Location to route to */
  to: string;
}

/**
 * A list item link that will navigate with the react router
 * @param props The list item link props
 * @returns The list item link
 */
export function ListItemLink(props: ListItemLinkProps): ReactElement {
  const location = useLocation();
  const { icon, primary, to } = props;

  const selected = to === location.pathname;

  return (
    <li>
      <ListItemButton component={NavLink} to={to} selected={selected}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}
