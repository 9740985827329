/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { useState, type ReactElement, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import numbro from "numbro";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ExecutionStatusEnum } from "@sophos-socos/admin-api-client";
import { Error } from "@mui/icons-material";
import { ALL_REGIONS_KEY } from "../../helpers/AllRegionsKey";

/**
 * The contents of a row of the table
 */
interface TableItem {
  /* ID value to display in first row */
  id: string;
  /* Count value to display in second row */
  count: number;
}

/** Props for the tabbed response table */
type TabbedResponseTable = {
  /** The table data */
  data: Map<
    /* The region key for the data */
    string,
    {
      /* The execution status for the region key */
      executionStatus: ExecutionStatusEnum;
      /* The items to display in the table for the region key */
      items: TableItem[];
    }
  >;
};

/**
 * Tabbed response table component to render the response data from multiple regions
 * @returns Tabbed response table component
 */
export function TabbedResponseTable(props: TabbedResponseTable): ReactElement {
  /* Selected tab in the table */
  const [activeTab, setActiveTab] = useState(ALL_REGIONS_KEY);

  // Calculate table data based on selected tab
  const tableData: TableItem[] = useMemo(() => {
    if (props.data !== undefined) {
      if (activeTab === ALL_REGIONS_KEY) {
        const allRegionsData: Record<string, number> = {};

        // Deduplicate / sum repeated IDs in different regions
        Array.from(props.data.values()).forEach((region) => {
          region.items.forEach((item) => {
            const currentCount = allRegionsData[item.id] || 0;
            allRegionsData[item.id] = currentCount + item.count;
          });
        });

        // Convert deduplicated map into table data shape
        return Object.entries(allRegionsData).map(([id, count]) => ({
          id,
          count,
        }));
      } else {
        const specificTab = props.data.get(activeTab);
        if (specificTab) {
          return specificTab.items;
        }
      }
    }

    return [];
  }, [activeTab, props.data]);

  return (
    <TableContainer aria-label="tabbed-response-table" component={Paper}>
      <Tabs variant="scrollable" scrollButtons="auto" value={activeTab} onChange={(_, value) => setActiveTab(value)} aria-label="region results tabs">
        <Tab key={ALL_REGIONS_KEY} label={<Typography>All Regions (merged)</Typography>} value={ALL_REGIONS_KEY} />
        {Array.from(props.data.keys()).map((region) => (
          <Tab
            key={region}
            label={
              <Box display="flex" flexDirection="row" alignItems="center">
                {props.data.get(region)?.executionStatus === ExecutionStatusEnum.Failure && (
                  <Error color="error" sx={{ pr: 1 }} />
                )}
                <Typography>{region}</Typography>
              </Box>
            }
            value={region}
          />
        ))}
      </Tabs>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData
              .sort((itemOne, itemTwo) => itemTwo.count - itemOne.count)
              .map((item) => (
                <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell align="right">{numbro(item.count).format({ thousandSeparated: true })}</TableCell>
                </TableRow>
              ))}
          {activeTab !== ALL_REGIONS_KEY &&
            props.data.get(activeTab)?.executionStatus === ExecutionStatusEnum.Failure && (
              <TableRow>
                <TableCell>
                  <Box sx={{ m: 3 }} display="flex" justifyContent="center" alignItems="center">
                    <Error color="error" sx={{ pr: 1 }} />
                    Failed to find results.
                  </Box>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
