/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { handleError } from "./apiError";
import { ErrorApi } from "@sophos-socos/admin-api-client";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { refreshAtom } from "../helpers/atoms";
import { accessTokenAtom } from "./userService";

/** The API */
const errorApi = new ErrorApi(DEFAULT_OPTIONS, config.apiUri);

/**
 * The query error fields.
 * @param query The query to be run.
 * @param region The region in which the query should run.
 */
export type QueryErrorFields = { query: string; region: string };

/** The request input fields */
export const queryErrorFields = atom<null | QueryErrorFields>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the query error API
 * @param page The number of the page that has been updated
 */
export const queryErrorState = atomFamily((page: number) =>
  atom(async (get) => {
    const accessToken = get(accessTokenAtom);
    const fields = get(queryErrorFields);
    if (fields !== null) {
      // Make this refreshable
      get(refreshGetter);
      try {
        const { data } = await errorApi.errorQuery(
          fields.region,
          page * config.queryErrorPageSize,
          config.queryErrorPageSize,
          {
            query: fields.query,
          },
          { headers: { Authorization: accessToken } },
        );
        return data;
      } catch (error: unknown) {
        handleError(error as Error, refreshSetter);
        throw error;
      }
    }
  }),
);
