/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */

/**
 * Validation rules for a guid
 */
export const guidFormRules = {
    minLength: { value: 36, message: "Min length: 36" },
    maxLength: { value: 36, message: "Max length: 36" },
    pattern: {
      value: RegExp("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"),
      message: "Must be valid GUID",
    },
  };