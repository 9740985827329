/**
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
/* eslint-disable react/display-name */
import throttle from "lodash/throttle";
import React, { useState, useCallback, useEffect, useMemo, HTMLAttributes, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { DraggableCore } from "react-draggable";
import { v4 } from "uuid";
import { DragHandle } from "@mui/icons-material";

/** The resizable panel props */
export type ResizablePanelProps = PropsWithChildren<{
  /** An optional key that will cache the user set size in localstorage. Note, that this cannot be changed. The first value will be used */
  cacheInLocalStorageKey?: string;
  /** Initial size in pixels of the absolute panel */
  initialSize?: number;
}> &
  HTMLAttributes<HTMLDivElement>;

/** The base panel that splits it's children using css grid */
const PanelBase = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

/** A child panel that contains the provided children */
const Panel = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

/** The separator for resizing the panel */
const Separator = styled.div<{ dragging: boolean }>`
  user-select: none;
  cursor: n-resize;
  position: relative;

  background-color: ${({ dragging }) => (dragging ? "#e0e0e0" : "unset")};

  :hover {
    background-color: #e0e0e0;
  }
`;

/** The separator for resizing the panel */
const SeparatorIcon = styled(DragHandle)`
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
`;

/** The resizable split panel component */
export const ResizablePanel = ({
  initialSize,
  cacheInLocalStorageKey,
  children,
  ...divProps
}: ResizablePanelProps): JSX.Element => {
  const baseRef = React.useRef<HTMLDivElement | null>(null);
  const handleId = useMemo(() => `id${v4()}`, []);

  const splitRef = React.useRef<number | null>(null);
  // Check if we're in our first render, and if so read the stored split value before rendering, this prevents a 'flash' of a default 0.5 split render
  let sizeValue = initialSize || 200;
  if (cacheInLocalStorageKey !== undefined && splitRef.current === null) {
    const storedSplit = localStorage.getItem(`@@resizablePanel/${cacheInLocalStorageKey}`);

    if (storedSplit !== null) {
      sizeValue = +storedSplit;
    }
  }

  // Store the state for if we're currently dragging, and the current split value
  const [dragging, setDragging] = useState(false);
  const [size, setSize] = useState(sizeValue);
  // Store the latest split value in a ref so it can be accessed by the cleanup
  splitRef.current = size;

  // If we have a storage key, set the value on unmount
  const saveSize = useCallback(() => {
    if (cacheInLocalStorageKey !== undefined && splitRef.current) {
      localStorage.setItem(`@@resizablePanel/${cacheInLocalStorageKey}`, splitRef.current.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Add a listener for page refreshes or closing the browser window
    if (cacheInLocalStorageKey !== undefined) {
      window.addEventListener("beforeunload", saveSize);
    }

    // Or on unmounting the component
    return saveSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // throttled callback for dragging
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDrag = useCallback(
    throttle((x: number, y: number, isDragging: boolean) => {
      if (baseRef.current && isDragging && x > 0 && y > 0) {
        const position = y - baseRef.current.offsetTop;
        setSize(position);
      }
    }),
    [setSize],
  );

  return (
    <DraggableCore
      handle={`#${handleId}`}
      onStart={(event) => {
        event.preventDefault();
        setDragging(true);
      }}
      onStop={(event) => {
        event.preventDefault();
        setDragging(false);
      }}
      onDrag={(event, data) => {
        event.preventDefault();
        throttledDrag(data.x, data.y, dragging);
      }}
      nodeRef={baseRef}
    >
      <PanelBase
        {...divProps}
        data-testid="resizable-panel"
        // inline styling as styled components need to re-generate classes every time property changes
        style={{
          ...divProps.style,
          gridTemplateRows: "1fr 0.5rem",
          height: `calc(${size}px + 0.25rem)`,
        }}
        ref={baseRef}
      >
        <Panel>{children}</Panel>
        <Separator role="separator" aria-orientation="vertical" id={handleId} dragging={dragging}>
          <SeparatorIcon />
        </Separator>
      </PanelBase>
    </DraggableCore>
  );
};
