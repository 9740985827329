/**
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { PrimitiveAtom, WritableAtom, atom } from "jotai";

/**
 * Creates a refreshable atom that can be composed into existing atoms to add a refresh functionality.
 * @returns The refresh getter and setter atoms
 * @returns 0 Refresh value atom, `get` this in the atom you want refresh behaviour
 * @returns 1 Refresh setter atom, this can be used to trigger the refresh
 */
export function refreshAtom(): [PrimitiveAtom<number>, WritableAtom<null, [], void>] {
  // Refresh value atom, `get` this in the atom you want refresh behaviour
  const refreshCount = atom(0);

  // Refresh setter atom, this can be used to
  const refresh = atom(null, (get, set) => {
    // Increment the count to trigger a refresh
    set(refreshCount, get(refreshCount) + 1);
  });

  return [refreshCount, refresh];
}
