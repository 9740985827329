/**
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
/* eslint-disable react/display-name */
import throttle from "lodash/throttle";
import React, { useState, useCallback, useEffect, useMemo, HTMLAttributes } from "react";
import { ReactNode } from "react";
import styled from "@emotion/styled";
import { DraggableCore } from "react-draggable";
import { v4 } from "uuid";
import { DragHandle } from "@mui/icons-material";

/** The resizable split panel props */
export type ResizableSplitPanelProps = {
  /** An optional key that will cache the user set split ratio in localstorage. Note, that this cannot be changed. The first value will be used */
  cacheInLocalStorageKey?: string;
  /** The orientation of the split panel */
  orientation?: "vertical" | "horizontal";
  /** The first panel (left or top) */
  first: ReactNode;
  /** The second panel (right or bottom) */
  second: ReactNode;
  /** Initial split value, a ratio by default, but if `absoluteSize` is set, the size in pixels of the absolute panel */
  initialSplitValue?: number;
  /** Whether to use the absolute size, rather than relative size. Useful if you want one to be an adjustable fixed sized panel */
  absoluteSize?: "first" | "second";
} & HTMLAttributes<HTMLDivElement>;

/** The base panel that splits it's children using css grid */
const PanelBase = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

/** A child panel that contains the provided children */
const Panel = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

/** The separator for resizing the panel */
const Separator = styled.div<{
  orientation?: "vertical" | "horizontal";
  dragging: boolean;
}>`
  user-select: none;
  cursor: ${({ orientation }) => (orientation === "vertical" ? "n-resize" : "e-resize")};
  position: relative;

  background-color: ${({ dragging }) => (dragging ? "#e0e0e0" : "unset")};

  :hover {
    background-color: #e0e0e0;
  }
`;

/** The separator for resizing the panel */
const SeparatorIcon = styled(DragHandle)<{
  orientation?: "vertical" | "horizontal";
}>`
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;

  transform: ${({ orientation }) => (orientation === "vertical" ? "unset" : "rotate(90deg)")};
`;

/** The resizable split panel component */
export const ResizableSplitPanel = ({
  cacheInLocalStorageKey,
  orientation,
  first,
  second,
  initialSplitValue,
  absoluteSize,
  ...divProps
}: ResizableSplitPanelProps): JSX.Element => {
  const baseRef = React.useRef<HTMLDivElement | null>(null);
  const handleId = useMemo(() => `id${v4()}`, []);

  const splitRef = React.useRef<number | null>(null);
  // Check if we're in our first render, and if so read the stored split value before rendering, this prevents a 'flash' of a default 0.5 split render
  let splitValue = initialSplitValue || (absoluteSize === undefined ? 0.5 : 200);
  if (cacheInLocalStorageKey !== undefined && splitRef.current === null) {
    const storedSplit = localStorage.getItem(`@@resizableSplit/${cacheInLocalStorageKey}`);

    if (storedSplit !== null) {
      splitValue = +storedSplit;
    }
  }

  // Store the state for if we're currently dragging, and the current split value
  const [dragging, setDragging] = useState(false);
  const [split, setSplit] = useState(splitValue);
  // Store the latest split value in a ref so it can be accessed by the cleanup
  splitRef.current = split;

  // If we have a storage key, set the value on unmount
  const saveSplit = useCallback(() => {
    if (cacheInLocalStorageKey !== undefined && splitRef.current) {
      localStorage.setItem(`@@resizableSplit/${cacheInLocalStorageKey}`, splitRef.current.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Add a listener for page refreshes or closing the browser window
    if (cacheInLocalStorageKey !== undefined) {
      window.addEventListener("beforeunload", saveSplit);
    }

    // Or on unmounting the component
    return saveSplit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // throttled callback for dragging
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDrag = useCallback(
    throttle((x: number, y: number, isDragging: boolean) => {
      if (baseRef.current && isDragging && x > 0 && y > 0) {
        const position = orientation === "vertical" ? y - baseRef.current.offsetTop : x - baseRef.current.offsetLeft;

        if (absoluteSize === "first") {
          setSplit(position);
        } else if (absoluteSize === "second") {
          setSplit(position);
        } else {
          setSplit(
            Math.max(
              Math.min(
                position / (orientation === "vertical" ? baseRef.current.offsetHeight : baseRef.current.offsetWidth),
                1,
              ),
              0,
            ),
          );
        }
      }
    }),
    [setSplit],
  );

  return (
    <DraggableCore
      handle={`#${handleId}`}
      onStart={(event) => {
        event.preventDefault();
        setDragging(true);
      }}
      onStop={(event) => {
        event.preventDefault();
        setDragging(false);
      }}
      onDrag={(event, data) => {
        event.preventDefault();
        throttledDrag(data.x, data.y, dragging);
      }}
      nodeRef={baseRef}
    >
      <PanelBase
        {...divProps}
        data-testid="resizable-panel"
        // inline styling as styled components need to re-generate classes every time property changes
        style={{
          ...divProps.style,
          [orientation === "vertical" ? "gridTemplateRows" : "gridTemplateColumns"]: `${
            absoluteSize === "first"
              ? `${split}px`
              : absoluteSize === "second"
              ? "1fr"
              : `${split < 1 ? split / (1 - split) : 1}fr`
          } 0.5rem ${
            absoluteSize === "second" ? `${split}px` : absoluteSize === "first" ? "1fr" : `${split < 1 ? 1 : 0}fr`
          }`,
        }}
        ref={baseRef}
      >
        <Panel>{first}</Panel>
        <Separator
          role="separator"
          aria-orientation={orientation}
          orientation={orientation}
          id={handleId}
          dragging={dragging}
        >
          <SeparatorIcon orientation={orientation} />
        </Separator>
        <Panel>{second}</Panel>
      </PanelBase>
    </DraggableCore>
  );
};
