/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { FilterApi } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { handleError } from "./apiError";
import { accessTokenAtom } from "./userService";
import { loadable } from "jotai/utils";
import { refreshAtom } from "../helpers/atoms";

/** The API */
const filterStatisticsApi = new FilterApi(DEFAULT_OPTIONS, config.apiUri);

/** Common filter statistics api fields used across various operations */
export type FilterStatisticsCommonFields = {
  /** The number of days to query */
  numberOfDaysForQuery: number;
  /** The region to query (empty will query all regions) */
  region?: string;
};

/** Fields specific to the rules hit by organisation API operation */
export type RulesHitByOrgFields = FilterStatisticsCommonFields & {
  /** The organisation ID for the query */
  organisationId: string;
};

/** Fields specific to the orgs that hit rule API operation */
export type OrgsThatHitRuleFields = FilterStatisticsCommonFields & {
  /** The ID of the filter for the query */
  filterId: string;
};

/** The rules hit by org input fields */
export const rulesHitByOrgFields = atom<null | RulesHitByOrgFields>(null);

/** The orgs that hit rule input fields */
export const orgsThatHitRuleFields = atom<null | OrgsThatHitRuleFields>(null);

/** The most common rules input fields */
export const mostCommonRulesFields = atom<null | FilterStatisticsCommonFields>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the hit rules by org API
 * @param [field, idToken]
 */
const rulesHitByOrgState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(rulesHitByOrgFields);
  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      const { data } = await filterStatisticsApi.listOrganisationFilters(
        fields.organisationId,
        fields.region,
        fields.numberOfDaysForQuery,
        { headers: { Authorization: accessToken } },
      );

      return data;
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/**
 * Selector for the orgs that hit rule API
 * @param [field, idToken]
 */
const orgsThatHitRuleState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(orgsThatHitRuleFields);
  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      const { data } = await filterStatisticsApi.getFilter(
        fields.filterId,
        fields.region,
        fields.numberOfDaysForQuery,
        { headers: { Authorization: accessToken } },
      );

      return data;
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/**
 * Selector for the most common rules API
 * @param [field, idToken]
 */
const mostCommonRulesState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(mostCommonRulesFields);
  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      const { data } = await filterStatisticsApi.listFilters(fields.region, fields.numberOfDaysForQuery, {
        headers: { Authorization: accessToken },
      });

      return data;
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/** Loadable setter for RulesHitByOrgState */
export const loadableRulesHitByOrgState = loadable(rulesHitByOrgState);
/** Loadable setter for deleteDeadLetterQueueState */
export const loadableOrgsThatHitRuleState = loadable(orgsThatHitRuleState);
/** Loadable setter for redriveDeadLetterQueueState */
export const loadableMostCommonRulesState = loadable(mostCommonRulesState);
